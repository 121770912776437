<template>
     <div class="ml--4 mr--4 mt-4">
    <div>.</div>
    <div :class="{ 'mt-5': true }">
      <div
        v-if="!item || !item.name"
        class="container-fluid mt-5"
        style="max-width: 83.33333333%"
      >
        <el-skeleton :rows="6" animated>
          <template slot="template">
            <div style="padding: 14px">
              <el-skeleton-item
                variant="text"
                style="margin-right: 0; height: 100px"
              /><br />
              <el-skeleton-item
                class="mt-3"
                variant="image"
                style="width: 100%; height: 480px"
              /><br />
              <el-skeleton-item
                variant="text"
                style="margin-right: 0; width: 50%"
              /><br />
              <el-skeleton-item
                variant="text"
                style="margin-right: 0; width: 30%"
              /><br />
              <el-skeleton-item
                variant="text"
                style="margin-right: 0; width: 40%"
              /><br />
              <el-skeleton-item
                variant="text"
                style="margin-right: 0; width: 100%"
              /><br />
              <el-skeleton-item
                variant="text"
                style="margin-right: 0; width: 100%"
              /><br />
              <el-skeleton-item
                variant="text"
                style="margin-right: 0; width: 30%"
              /><br />
              <el-skeleton-item
                variant="text"
                style="margin-right: 0; width: 40%"
              /><br />
              <el-skeleton-item
                variant="text"
                style="margin-right: 0; width: 30%"
              /><br />
              <el-skeleton-item
                variant="text"
                style="margin-right: 1%; width: 32%"
              />
              <el-skeleton-item
                variant="text"
                style="margin-right: 1%; width: 32%"
              />
              <el-skeleton-item
                variant="text"
                style="margin-right: 1%; width: 32%"
              />
            </div>
          </template>
        </el-skeleton>
      </div>
      <base-sponsor-page 
        v-if="item && item.name" 
        :item="item" 
        :modals="modals" 
      />
    </div>
  </div>
</template>
<script>
import BaseSponsorPage from "@/components/Sponsors/BaseSponsorPage.vue";
import {
  Option,
  Select,
  Image,
  Carousel,
  CarouselItem,
  Skeleton,
  SkeletonItem,
} from "element-ui";
export default {
  name: "PublicEventPage",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Image.name]: Image,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
    [Skeleton.name]: Skeleton,
    [SkeletonItem.name]: SkeletonItem,
    BaseSponsorPage,
  },
  props: {
    backgroundColor: {
      type: String,
      default: "black",
    },
    logo: {
      type: String,
      default: process.env.VUE_APP_DEFAULT_LOGO,
      description: "Sidebar app logo",
    },
  },
  data() {
    return {
      attendee: { name: "", email: "", type: "attendees" },
      item: { id: null },
      skeletonLoader: false,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      modals: {
        openBooking: false,
        openVideo: false,
      },
      copyrightYear: new Date().getFullYear(),
    };
  },
  async created() {
    await this.fetchItem();
  },
  beforeDestroy() {
    // Reset google analytics
    this.setGoogleAnalytics();
  },
  methods: {
    async fetchItem() {
      try {
        const id = (this.item.id = this.$route.params.id);
        let params = {
          include: "categories",
        };
        await this.$store
          .dispatch("sponsors/getPublic", { id, params })
          .then(() => (this.item = this.$store.getters["sponsors/sponsor"]));
        try {
          // Send action to Google Analytics
          this.$gtag.event("bmis_view_public_event_page", {
            eventId: this.$route.params.id,
          });
        } catch (error) {
          // Notification
          this.$notify({
            type: "danger",
            message: error,
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    goBack() {
      this.$router.push({ name: "List Sponsors" });
    },
  },
};
</script>